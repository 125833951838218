import { Dots } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import { InstructionColor } from '../../../../lib/instructionSettings';
import { SingleTheme } from './Common';

type Props = {
  themes?: SingleTheme[];
  current?: number;
};

const Theme: React.FC<Props> = props => {
  return (
    <ThemeStyle>
      {props.current == null ? (
        <span>お題</span>
      ) : (
        <span>{props.current + 1}つ目のお題</span>
      )}
      <div>
        <p>{props.children}</p>
        <span>
          {props.themes && (
            <Dots
              max={props.themes.length}
              current={props.current || 0}
              color={InstructionColor.primary}
            />
          )}
        </span>
      </div>
    </ThemeStyle>
  );
};

const ThemeStyle = styled.div`
  width: 250px;
  margin-bottom: 20px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  align-self: flex-start;

  > span {
    display: block;
    text-align: center;
    background: #4f78ff;
    color: white;
    font-weight: bold;
    font-size: 2.6rem;
  }
  > div {
    text-align: center;
    background: white;
    font-weight: bold;
    font-size: 3rem;
    border: 1px solid #ced4da;
    white-space: pre-wrap;
    border-top: none;
    padding: 0 10px;
    padding-top: 6px;

    p {
      padding: 14px 0;
    }

    span {
      width: 100%;
      display: block;
      margin-bottom: 16px;

      ul {
        width: 100%;
        justify-content: center;
      }
    }
  }
`;
export default Theme;
