import { CommonBG, CountDown } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useStatePersist as useState } from 'use-state-persist';

interface CountDownProps {
  redirect: string;
}
const Switch2CountDown: React.FC<CountDownProps> = props => {
  const location = useLocation();
  const { trainingId } = useParams<'trainingId'>();
  const [resultSet] = useState<any>(
    `switch2-${trainingId}-resultSet`,
    location.state
  );

  return (
    <CommonBG>
      <CountDown
        redirect={'/' + trainingId + props.redirect}
        state={resultSet}
      />
    </CommonBG>
  );
};

export default Switch2CountDown;
