import {
  CommonBG,
  HeaderOptions,
  StageSelect,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  InstructionColor,
  InstructionLogo,
} from '../../../lib/instructionSettings';

const headerOptions: HeaderOptions = {
  hideHomeButton: true,
  hideSettingButton: true,
};

interface SelectStageProps {}
export const SelectStage: React.FC<SelectStageProps> = () => {
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();
  return (
    <CommonBG withHeader headerOptions={headerOptions} withFooter>
      <StageSelect
        onBack={() => navigate(`/${trainingId}/`)}
        onFinish={() => navigate(`/${trainingId}/finish`)}
        stages={[
          {
            name: 'ツッコミ大喜利\nバトル',
            path: '/' + trainingId + '/switch1/page1',
          },
          {
            name: '全否定\nシンキング',
            path: '/' + trainingId + '/switch2/introduction',
          },
          {
            name: 'ブレストビンゴ！',
            path: '/' + trainingId + '/switch3/1/introduction',
          },
        ]}
        color={InstructionColor}
        logo={InstructionLogo}
      />
    </CommonBG>
  );
};
export default SelectStage;
