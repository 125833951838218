import { CommonBG, Rule } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import { deleteData, hasData } from '../../../localStorage/LocalStorageManager';
import Dialog from '../../../uiElements/Dialog';
import { Switch3State } from './Common';
import { themes } from './WordList.json';

interface RuleInstrctionProps {
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
}

const Switch3Rule: React.FC<RuleInstrctionProps> = () => {
  const params = useParams<'trainingId' | 'stage'>();
  const navigate = useNavigate();

  const location = useLocation();
  const [resultSet] = useState<Switch3State>(
    `switch3-${params.trainingId}-resultSet`
  );

  const text = InstructionText[3];
  const [isShowModal, setIsShowModal] = React.useState<boolean>(false);

  if (Number.isNaN(parseInt(params.stage ?? 'undefined'))) {
    return null;
  }

  const stage_id = parseInt(params.stage ?? 'undefined');
  if (stage_id > themes.length) {
    return null;
  }

  return (
    <CommonBG>
      <Rule
        title={text.title}
        mission={text.mission}
        description={text.description}
        video={text.video}
        onStart={
          params.trainingId &&
          hasData('switch3', params.trainingId) &&
          stage_id === 1
            ? () => {
                setIsShowModal(true);
              }
            : () =>
                navigate(`/${params.trainingId}/switch3/${stage_id}/countdown`)
        }
        onBack={
          stage_id === 1
            ? () => navigate(`/${params.trainingId}/stages`)
            : undefined
        }
        color={InstructionColor}
        logo={InstructionLogo}
      />
      <Dialog
        message={
          '前回の進捗があります。続きから始めますか？\n新規に始める場合は「新規に始める」を選択してください。'
        }
        isShow={isShowModal}
        cancelText={'新規に始める'}
        onCancel={() => {
          if (!params.trainingId) {
            return;
          }
          deleteData('switch3', params.trainingId);
          navigate(`/${params.trainingId}/switch3/1/countdown`);
        }}
        onConfirm={() => {
          const currentPage =
            localStorage.getItem('currentpage-switch3') ?? '1/countdown';
          navigate(`/${params.trainingId}/switch3/${currentPage}`);
        }}
      />
    </CommonBG>
  );
};

export default Switch3Rule;
