import { useSignIn } from '@riddler-co-jp/specc-auth-client';
import React from 'react';
import { Navigate, Route, Routes, useMatch, useParams } from 'react-router-dom';

import FinishPage from './components/eventProvider/common/FinishPage';
import Loading from './components/eventProvider/common/Loading';
import SelectStage from './components/eventProvider/common/SelectStage';
import StartPage from './components/eventProvider/common/StartPage';
import Switch1Page1 from './components/eventProvider/stagePages/switch1/Switch1Page1';
import Switch1Page2 from './components/eventProvider/stagePages/switch1/Switch1Page2';
import Switch1Page3 from './components/eventProvider/stagePages/switch1/Switch1Page3';
import Switch2CountDown from './components/eventProvider/stagePages/switch2/Switch2CountDown';
import Switch2Game from './components/eventProvider/stagePages/switch2/Switch2Game';
import Switch2Result from './components/eventProvider/stagePages/switch2/Switch2Result';
import Switch2Rule from './components/eventProvider/stagePages/switch2/Switch2Rule';
import Switch3CountDown from './components/eventProvider/stagePages/switch3/Switch3CountDown';
import Switch3Game from './components/eventProvider/stagePages/switch3/Switch3Game';
import Switch3Result from './components/eventProvider/stagePages/switch3/Switch3Result';
import Switch3Rule from './components/eventProvider/stagePages/switch3/Switch3Rule';
import { app } from '.';

interface ConditionedRouteRule {
  condition: boolean;
  redirectPathOnFail: string;
}
interface ConditionedRouteProps {
  rules: ConditionedRouteRule[];
}

const ConditionedRoute: React.FC<ConditionedRouteProps> = props => {
  for (const rule of props.rules) {
    if (!rule.condition) {
      return <Navigate to={rule.redirectPathOnFail} />;
    }
  }

  return <>{props.children}</>;
};

const debug = true;

export const TrainingRouter = () => {
  const { trainingId } = useParams<'trainingId'>();
  const match = useMatch('/:trainingId/*');
  const [signedIn, signInLoading] = useSignIn(
    app,
    'switch',
    trainingId,
    match?.pathname === match?.pathnameBase ||
      match?.pathname === `${match?.pathnameBase}/`
  );
  const onlyValidUserRules = React.useMemo<ConditionedRouteRule[]>(() => {
    return [
      {
        condition: debug || (!signInLoading && signedIn),
        redirectPathOnFail: '/auth-error',
      },
    ];
  }, [signInLoading, signedIn]);

  const handleBeforeUnloadEvent = (event: BeforeUnloadEvent): void => {
    event.returnValue =
      'このページを再読み込みしますか？入力した内容は保存されません。';
  };

  React.useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnloadEvent);
    return () =>
      window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
  }, []);

  const onExit = (url: string) => {
    window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
    window.location.href = url;
  };

  if (signInLoading) {
    return <Loading />;
  }

  const page = match?.pathname.split('/') ?? [];
  if (['switch1', 'switch2', 'switch3'].includes(page[2])) {
    const currentPage = page.slice(3).join('/');
    if (currentPage !== '' && !currentPage.match(/introduction/)) {
      localStorage.setItem('currentpage-' + page[2], currentPage);
    }
  }

  return (
    <Routes>
      {/* switch1 */}
      <Route
        path='/switch1/page1'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Switch1Page1 />
          </ConditionedRoute>
        }
      />
      <Route
        path='/switch1/page2'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Switch1Page2 />
          </ConditionedRoute>
        }
      />
      <Route
        path='/switch1/page3'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Switch1Page3 />
          </ConditionedRoute>
        }
      />
      {/* switch2 */}
      <Route
        path='/switch2/introduction'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Switch2Rule />
          </ConditionedRoute>
        }
      />
      <Route
        path='/switch2/game1/'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Switch2Game stage={1} />
          </ConditionedRoute>
        }
      />
      <Route
        path='/switch2/game2/'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Switch2Game stage={2} />
          </ConditionedRoute>
        }
      />
      <Route
        path='/switch2/result'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Switch2Result />
          </ConditionedRoute>
        }
      />
      <Route
        path='/switch2/countdown1'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Switch2CountDown redirect='/switch2/game1' />
          </ConditionedRoute>
        }
      />
      <Route
        path='/switch2/countdown2'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Switch2CountDown redirect='/switch2/game2' />
          </ConditionedRoute>
        }
      />
      {/* switch3 */}
      <Route
        path='/switch3/:stage/introduction'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Switch3Rule />
          </ConditionedRoute>
        }
      />
      <Route
        path='/switch3/:stage/game'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Switch3Game />
          </ConditionedRoute>
        }
      />
      <Route
        path='/switch3/:stage/countdown'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Switch3CountDown />
          </ConditionedRoute>
        }
      />
      <Route
        path='/switch3/result'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <Switch3Result />
          </ConditionedRoute>
        }
      />
      <Route
        path='/'
        element={
          signInLoading ? (
            <Loading />
          ) : (
            <ConditionedRoute rules={onlyValidUserRules}>
              <StartPage />
            </ConditionedRoute>
          )
        }
      />
      <Route
        path='/stages'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <SelectStage />
          </ConditionedRoute>
        }
      />
      <Route
        path='/finish'
        element={
          <ConditionedRoute rules={onlyValidUserRules}>
            <FinishPage onExit={onExit} />
          </ConditionedRoute>
        }
      />
    </Routes>
  );
};
