import { CommonBG } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import Switch2Field from './Switch2Field';

interface Stage2Props {
  stage: number;
}

const Stage2: React.FC<Stage2Props> = props => {
  return (
    <CommonBG>
      <MainWrapper>
        <Switch2Field
          TotalTime={900}
          Step1Time={180}
          Step2Time={120}
          turn={props.stage - 1}
        />
      </MainWrapper>
    </CommonBG>
  );
};

const MainWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 3rem);
  margin: 3rem auto 0;
  display: flex;
  overflow: hidden;
`;

export default Stage2;
