import { InstructionColors } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';

export const InstructionLogo: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='255.333'
      height='60'
      viewBox='0 0 255.333 60'
    >
      <g transform='translate(-4744 -2682)'>
        <g transform='translate(4536.336 2662)'>
          <path
            d='M35.02,27.34V25.26c0-4.8-3.48-8.4-10.12-8.4H16.02c-.72,0-.92-.4-.92-.88v-.16c0-.44.28-.76.92-.76H32.86V4.7H13.94C6.78,4.7,2.58,7.5,2.58,14.78v1.16c0,6.28,3.2,9.6,11.28,9.6h7.96c.52,0,.8.2.8.76v.12c0,.52-.2.76-.8.76H3.22V37.3H24.26C32.06,37.3,35.02,32.66,35.02,27.34Zm37.68.68V13.26H62.1V25.3c0,1.52-.72,1.92-2.24,1.92h-.32V13.26H51.26V27.22h-.24c-1.52,0-2.24-.4-2.24-1.92V13.26H38.18V28.02c0,5.64,3.56,9.36,9.72,9.36H62.98C69.18,37.38,72.7,33.66,72.7,28.02ZM88.26,13.74V4.7H76.78v9.04Zm0,23.64V15.58H76.78v21.8Zm32.92-13.64V13.22h-13V8.66H96.86v4.56h-5V23.74h5V29.5c0,4.76,2.36,7.88,9,7.88h14.76V26.94h-11c-1.28,0-1.44-.2-1.44-1.28V23.74Zm31.48,13.64V26.78H136.38c-1.08,0-1.6-.28-1.6-.96v-.96c0-.56.32-.96,1.56-.96h16.32V13.26H134.38c-8.24,0-10.84,3.92-10.84,10.32V27.1c0,6.48,2.4,10.28,10.88,10.28Zm34.2,0V23.14c0-7.36-3.08-9.88-9.48-9.88H170.9a7.022,7.022,0,0,0-2.64.6V4.7H156.74V37.38h11.52V25.82c0-1.36.44-1.92,1.76-1.92h3.56c1.36,0,1.76.68,1.76,1.76V37.38Z'
            transform='translate(276.137 29.1)'
            fill='#fff'
          />
        </g>
        <g transform='translate(4744 2682)'>
          <path
            d='M43.94,29.28H28.06A10.087,10.087,0,0,0,18,39.34v.84A10.087,10.087,0,0,0,28.06,50.24H43.94A10.087,10.087,0,0,0,54,40.18v-.84A10.087,10.087,0,0,0,43.94,29.28ZM28.527,44.213a4.453,4.453,0,1,1,4.447-4.453,4.38,4.38,0,0,1-4.447,4.453Z'
            transform='translate(-6 -9.76)'
            fill='#fff'
          />
          <path
            d='M30,0A30,30,0,1,0,60,30,30,30,0,0,0,30,0Zm0,57.6A27.6,27.6,0,1,1,57.6,30,27.6,27.6,0,0,1,30,57.6Z'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  );
};

export const SpeccText = {
  title: 'Switch',
  mission: '多角的に物事を見る力を身につける。',
  description_start: '説明(開始)',
  description_finish: '説明(終了)',
  video:
    'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
};

interface InstructionText {
  title: string;
  mission: string;
  description: string;
  video: string;
  video_result: string;
}

export const InstructionText: { [v: number]: InstructionText } = {
  1: {
    title: 'ツッコミ大喜利バトル',
    mission: '固定観念に気付き「思考を切り替える」\nメソッドを身につける。',
    description:
      '動画で流れる問題を見ながら、グループでディスカッションしましょう。一見普通に見える文章・画像の中に、不自然な点（＝ツッコミポイント）があります。前提を疑い、Switchする感覚を身につけましょう。',
    video:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
    video_result:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
  },
  2: {
    title: '全否定シンキング',
    mission: '思考を切り替え、「あえて後戻りする」感覚を掴む。',
    description:
      'お題に対して、それが持っている特徴を正反対にすることで、新たなアイテムを考案しましょう。まずはアイテムの特徴を書き出し、次にその特徴を全てひっくり返しましょう。最後に正反対の要素を持った、全く新しい『お題アイテム』を考えててグループで発表しましょう。',
    video:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
    video_result:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
  },
  3: {
    title: 'ブレストビンゴ！',
    mission: '思考を切り替える「方向性」を増やす。',
    description:
      'お題とは全く無関係に思える「料理」「動物」のリストを使って、変則的なブレストに挑戦しましょう。リストの単語から連想される要素をお題に掛け合わせることで、制限時間内にアイデアを考案しましょう。使用した単語にはチェックを付けビンゴを目指しましょう。',
    video:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
    video_result:
      'https://dl.dropboxusercontent.com/s/0zw6pts700228yk/6-1%E8%A7%A3%E8%AA%AC_1.mp4',
  },
};

export const InstructionColor: InstructionColors = {
  primary: '#6C98FF',
  secondary: '#4F78FF',
  finishButton: '#426EFF',
  shadow: '#4167E4',
};
