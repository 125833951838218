import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommonBG, Result } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import { Item, Pair, Tuple } from './common/interface';

interface RuleInstrctionProps {
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
  infomations?: string[];
  conditions?: string[];
  consider_condition?: string;
  conclusions?: string[];
}

interface SingleResultProps {
  single: Item;
}

const SingleResult: React.FC<SingleResultProps> = props => {
  const [openDetail, setOpenDetail] = React.useState<boolean>(false);
  const detailRef = React.useRef<HTMLDivElement>(null);

  const detailHeight = detailRef.current?.offsetHeight || 0;

  return (
    <SingleResultBody>
      <Title>
        <h3>お題</h3>
        <div>
          <p>{props.single.title}</p>
          <span>
            <p>回答数</p>
            <p>{props.single.ideas.length}</p>
          </span>
        </div>
      </Title>
      <IdeaList>
        <IdeaTitle>
          <p>アイデアタイトル</p>
          <p>アイデアの説明</p>
        </IdeaTitle>
        <ul>
          {props.single.ideas.map((singleIdea: Tuple) => {
            return (
              <li key={singleIdea.id}>
                <span>{singleIdea.id + 1}</span>
                {singleIdea.title == '' ? (
                  <p>
                    <small>(未記入)</small>
                  </p>
                ) : (
                  <p>{singleIdea.title}</p>
                )}
                {singleIdea.explanation == '' ? (
                  <p>
                    <small>(未記入)</small>
                  </p>
                ) : (
                  <p>{singleIdea.explanation}</p>
                )}
              </li>
            );
          })}
        </ul>
      </IdeaList>
      <Details
        open={openDetail}
        onClick={() => {
          setOpenDetail(!openDetail);
        }}
        style={{ maxHeight: detailHeight + 47 }}
      >
        <div>
          <p>書き出した特徴</p>
          <span>
            <FontAwesomeIcon icon={faChevronDown} />
          </span>
        </div>

        <div ref={detailRef}>
          {props.single.features.length > 0 ? (
            <ul>
              {props.single.features.map((singleFeature: Pair) => {
                return (
                  <li key={singleFeature.id}>
                    <span>
                      <p>{singleFeature.id + 1}</p>
                    </span>
                    <p>{singleFeature.name}</p>
                  </li>
                );
              })}
            </ul>
          ) : (
            <NoContent>
              <small>(項目なし)</small>
            </NoContent>
          )}

          <ul>
            {props.single.featuresRev.map((singleFeature: Pair) => {
              return (
                <li key={singleFeature.id}>
                  <span>
                    <p>{singleFeature.id + 1}</p>
                  </span>
                  {singleFeature.name ? (
                    <p>{singleFeature.name}</p>
                  ) : (
                    <p style={{ color: '#888' }}>(未記入)</p>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </Details>
    </SingleResultBody>
  );
};

const Switch2Result: React.FC<RuleInstrctionProps> = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const { trainingId } = useParams<'trainingId'>();
  const [resultSet, setResultSet] = useState<any>(
    `switch2-${trainingId}-resultSet`
  );

  const text = InstructionText[2];

  return (
    <CommonBG>
      <Result
        title={text.title}
        mission={text.mission}
        video={text.video_result}
        onBack={() => navigate(`/${trainingId}/stages`)}
        color={InstructionColor}
        logo={InstructionLogo}
      >
        <PageTitle>あなたの回答</PageTitle>

        {resultSet && (
          <>
            {resultSet.results.map((single: Item) => {
              return <SingleResult single={single} />;
            })}
          </>
        )}
      </Result>
    </CommonBG>
  );
};

const PageTitle = styled.h2`
  font-size: 2.4rem;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const SingleResultBody = styled.div`
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;

  small {
    opacity: 0.4;
  }
`;

const Title = styled.div`
  display: flex;

  h3 {
    width: 120px;
    background: #4f78ff;
    color: white;
    font-weight: bold;
    font-size: 2.4rem;
    text-align: center;
    padding-top: 3px;
  }

  > div {
    background: white;
    padding: 4px 0;

    border: 1px solid #ced4da;
    border-left: none;
    flex: 1;
    display: flex;

    p {
      font-weight: bold;
      font-size: 2.4rem;
      flex: 1;
      margin: 0 16px;
    }

    > p {
      line-height: 3.4rem;
    }

    span {
      font-weight: bold;
      font-size: 2.4rem;
      display: flex;
      align-items: baseline;
      white-space: nowrap;

      p:first-child {
        font-size: 1.3rem;
        margin-right: 0px;
      }
    }
  }
`;

const IdeaList = styled.div`
  border: 1px solid #ced4da;
  border-top: none;
  background: white;

  ul {
    li {
      background: white;
      list-style: none;
      display: flex;
      font-size: 1.6rem;
      padding: 16px 0;

      span {
        font-weight: bold;
        width: 60px;
        text-align: center;
      }

      p:nth-child(2) {
        width: 220px;
        margin-right: 20px;
        overflow-wrap: anywhere;
      }

      p: nth-child(3) {
        flex: 1;
        white-space: pre-wrap;
        overflow-wrap: anywhere;
        padding-right: 10px;
      }
    }

    li:nth-child(2n) {
      background: #f3f4f6;
    }
  }
`;

const IdeaTitle = styled.div`
  display: flex;
  background: #f3f4f6;
  border-bottom: 1px solid #ced4da;
  font-size: 1.8rem;
  font-weight: bold;
  padding: 10px 16px;

  p:first-child {
    margin-left: 42px;
    width: 222px;
    margin-right: 20px;
  }
`;

interface DetailProps {
  open: boolean;
}

const Details = styled.div`
  cursor: pointer;
  border: 1px solid #ced4da;
  border-top: none;
  background: #f3f4f6;
  overflow: hidden;
  transition: 0.4s;

  > div:first-child {
    display: flex;
    align-items: center;

    p {
      flex: 1;
    }

    span {
      font-size: 2rem;
      margin-right: 20px;

      svg {
        transition: 0.2s;
      }
    }
  }

  p {
    font-size: 1.8rem;
    font-weight: bold;
    padding: 10px 16px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 4px;

    li {
      display: flex;
      width: calc(50% - 8px);
      background: white;
      list-style: none;
      margin: 4px;
      margin-top: 0;

      span {
        border: 2px solid #343a40;
        width: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #343a40;
        color: white;
      }

      > p {
        flex: 1;
        border: 1px solid #ced4da;
        font-size: 1.6rem;
        font-weight: normal;
      }
    }
  }

  ul:last-child {
    margin-top: -4px;
    li {
      span {
        border: 2px solid #343a40;
        background: white;
        color: #343a40;
      }
      > p {
        border: 1px solid #343a40;
        background: #343a40;
        color: white;
      }
    }
  }

  ${(p: DetailProps) => {
    return p.open
      ? `
        > div {
          svg {
            transform: rotate(180deg);
          }  
        }
    `
      : `
        max-height: 48px !important;
    `;
  }}
`;

const NoContent = styled.div`
  font-size: 1.6rem;
  padding: 10px 20px;
  text-align: center;

  small {
    margin: 0 auto;
  }
`;

export default Switch2Result;
