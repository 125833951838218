import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FinishButton } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import TimeGauge from '../../../uiElements/timeGauge/TimeGauge';
import { Item, Pair, Tuple } from './common/interface';
import Switch2Step1 from './Switch2Step1';
import Switch2Step2 from './Switch2Step2';
import Switch2Step3 from './Switch2Step3';
import { titles } from './Words.json';

interface Stage2FieldProps {
  turn: number;
  TotalTime: number;
  Step1Time: number;
  Step2Time: number;
}

const Stage2Field: React.FC<Stage2FieldProps> = props => {
  const { pathname } = useLocation();
  const { trainingId } = useParams<'trainingId'>();
  const [finished, setFinished] = useState<boolean>(
    `switch2-${trainingId}-finished`,
    false
  );
  const location = useLocation();

  const navigate = useNavigate();
  const [step, setStep] = useState<number>(`switch2-${trainingId}-step`, 1); // 現在のステップ(1~3)
  const [activeStep3, setActiveStep3] = useState(
    `switch2-${trainingId}-activeStep3`,
    false
  );
  const [tempFeatures, setTempFeatures] = useState<Pair[]>(
    `switch2-${trainingId}-tempFeatures`,
    []
  );
  const [tempFeaturesRev, _setTempFeaturesRev] = useState<Pair[]>(
    `switch2-${trainingId}-tempFeaturesRev`,
    []
  );
  const [selectedFeatures, setSelectedFeatures] = useState<number>(
    `switch2-${trainingId}-selectedFeatures`,
    0
  );
  const [tempIdeas, setTempIdeas] = useState<Tuple[]>(
    `switch2-${trainingId}-tempIdeas`,
    [{ id: 0, title: '', explanation: '' }]
  );
  const [resultSet, setResultSet] = useState<any>(
    `switch2-${trainingId}-resultSet`
  );

  useEffect(() => {
    setFinished(false);
  }, [pathname]);

  const goTo = (url: string) => {
    window.setTimeout(() => {
      navigate(url);
    }, 0);
  };

  const setTempFeaturesRev = (value: Pair[]) => {
    _setTempFeaturesRev(value);
    if (value.some((single: any) => single.name == null) == false) {
      setActiveStep3(true);
    } else {
      setActiveStep3(false);
    }
  };

  /**
   * 特徴データを末尾に追加
   * @param str
   * @returns
   */
  const addFeature = (str: string) => {
    if (str === '') {
      return;
    }
    const add: Pair = { id: tempFeatures.length, name: str };
    const tmp = [...tempFeatures, add];
    setTempFeatures(tmp);

    const add2: Pair = { id: tempFeatures.length, name: null };
    const tmp2 = [...tempFeaturesRev, add2];
    setTempFeaturesRev(tmp2);
  };

  /**
   * 逆の特徴データを追加
   * @param str
   * @returns
   */
  const addFeatureRev = (str: string) => {
    if (str === '') {
      return;
    }
    tempFeaturesRev[selectedFeatures].name = str;
    setTempFeaturesRev(tempFeaturesRev);
  };

  /**
   * 特徴データを削除
   * @param i
   * @returns
   */
  const deleteFeature = (i: number) => {
    const tmp = [...tempFeatures];
    tmp.splice(i, 1);
    for (let i = 0; i < tmp.length; i++) {
      tmp[i].id = i;
    }
    setTempFeatures(tmp);
    if (i >= tempFeaturesRev.length) return;
    const tmp2 = [...tempFeaturesRev];
    tmp2.splice(i, 1);
    for (let i = 0; i < tmp2.length; i++) {
      tmp2[i].id = i;
    }
    setTempFeaturesRev(tmp2);
    setSelectedFeatures(0);
  };

  const deleteIdea = (i: number) => {
    const tmp = [...tempIdeas];
    tmp.splice(i, 1);
    for (let i = 0; i < tmp.length; i++) {
      tmp[i].id = i;
    }
    setTempIdeas(tmp);
  };

  const addEntryField = () => {
    const add = { id: tempIdeas.length, title: '', explanation: '' };
    const tmp = [...tempIdeas, add];
    setTempIdeas(tmp);
  };

  const prevStep = () => {
    setStep(step - 1);
  };
  const nextStep = () => {
    setStep(step + 1);
  };

  const onFinish = () => {
    const sendData: Item = {
      questionCount: 0,
      title: titles[props.turn],
      features: tempFeatures,
      featuresRev: tempFeaturesRev,
      ideas: tempIdeas,
    };

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const results: Item[] = resultSet?.results || [];
    results.push(sendData);
    setTempFeatures([]);
    _setTempFeaturesRev([]);
    setSelectedFeatures(0);
    setTempIdeas([{ id: 0, title: '', explanation: '' }]);
    setActiveStep3(false);
    setStep(1);

    if (props.turn == 0) {
      setResultSet({ results });
      goTo(`/${trainingId}/switch2/countdown2`);
    } else {
      setResultSet({ results });
      goTo(`/${trainingId}/switch2/result`);
    }
  };
  return (
    <>
      <TimeGauge
        timerKey={trainingId + `-switch2-` + props.turn}
        duration={props.TotalTime}
        stageName={InstructionText[2].title}
        logo={InstructionLogo}
        color={InstructionColor}
        onSkip={onFinish}
        onFinished={() => {
          setFinished(true);
        }}
        onBack={() => {
          navigate(`/${trainingId}/stages`);
        }}
      />
      <Wrap>
        <Header>
          {step >= 2 ? (
            <Button
              onClick={prevStep}
              variant='sub'
              color='positive'
              size='medium'
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              前のステップへ
            </Button>
          ) : (
            <DmyBtn />
          )}
          <ul>
            <SingleStep selected={step == 1}>
              <p>STEP1</p>
              <span>{Math.floor(props.Step1Time / 60)}分</span>
            </SingleStep>
            <SingleStep selected={step == 2}>
              <p>STEP2</p>
              <span>{Math.floor(props.Step2Time / 60)}分</span>
            </SingleStep>
            <SingleStep selected={step == 3}>
              <p>STEP3</p>
              <span>
                {Math.floor(
                  (props.TotalTime - props.Step1Time - props.Step2Time) / 60
                )}
                分
              </span>
            </SingleStep>
          </ul>
          {step <= 2 ? (
            <Button
              onClick={nextStep}
              disabled={
                (step == 1 && tempFeatures.length <= 0) ||
                (step == 2 && activeStep3 == false)
              }
              variant='sub'
              color='positive'
              size='medium'
            >
              次のステップへ
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          ) : (
            <DmyBtn />
          )}
        </Header>
        {step == 1 && (
          <Switch2Step1
            turn={props.turn}
            onDeleteFeature={deleteFeature}
            onSendFeature={addFeature}
            tempFeatures={tempFeatures}
          />
        )}
        {step == 2 && (
          <Switch2Step2
            turn={props.turn}
            onSendFeatureRev={addFeatureRev}
            tempFeatures={tempFeatures}
            tempFeaturesRev={tempFeaturesRev}
            selectedFeatures={selectedFeatures}
            onChangeSelected={i => {
              setSelectedFeatures(i);
            }}
          />
        )}
        {step == 3 && (
          <Switch2Step3
            turn={props.turn}
            tempFeatures={tempFeatures}
            tempFeaturesRev={tempFeaturesRev}
            tempIdeas={tempIdeas}
            onDeleteIdea={i => deleteIdea(i)}
            onAddEntryField={() => addEntryField()}
            onSetTempIdeas={(i, val) => {
              const tmp = [...tempIdeas];
              tmp[i] = val;
              setTempIdeas(tmp);
            }}
          />
        )}

        <FinishButton
          color={InstructionColor}
          finished={finished}
          click={onFinish}
        >
          {props.turn == 0
            ? '次のお題に挑戦しましょう！'
            : '以上で実習は終了です。お疲れ様でした！'}
        </FinishButton>
      </Wrap>
    </>
  );
};

const Wrap = styled.div`
  width: 100%;
  position: relative;
`;

const DmyBtn = styled.div`
  width: 170px;
`;

const Header = styled.div`
  margin-top: 10px;
  padding: 8px 20px;
  width: calc(100% - 40px);
  background: white;
  position: absolute;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);

  ul {
    flex: 1;
    display: flex;
    margin: 0 20px;
    justify-content: center;

    li {
      list-style: none;
    }
  }

  button:first-child {
    svg {
      margin-right: 10px;
      margin-left: -4px;
    }
  }

  button:last-child {
    svg {
      margin-right: -4px;
      margin-left: 10px;
    }
  }
`;

interface singleStepProps {
  selected: boolean;
}

const SingleStep = styled.div`
  padding: 0 40px;
  font-weight: bold;
  text-align: center;
  margin-bottom: -8px;
  padding-bottom: 4px;
  p {
    font-size: 2rem;
  }
  span {
    font-size: 1.3rem;
  }

  ${(p: singleStepProps) => {
    return p.selected
      ? `
        color: #4F78FC;
        border-bottom: 4px solid #4F78FC;
    `
      : `
        color: #868E96;
        border-bottom: 4px solid white;
    `;
  }}
`;

export default Stage2Field;
