import { CommonBG, Rule } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import { deleteData, hasData } from '../../../localStorage/LocalStorageManager';
import Dialog from '../../../uiElements/Dialog';

interface RuleInstrctionProps {
  //stageId: number;
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
}

const text = InstructionText[2];

const Switch2Rule: React.FC<RuleInstrctionProps> = () => {
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();
  const [isShowModal, setIsShowModal] = React.useState<boolean>(false);
  return trainingId ? (
    <CommonBG>
      <Rule
        title={text.title}
        mission={text.mission}
        description={text.description}
        video={text.video}
        onStart={
          hasData('switch2', trainingId)
            ? () => {
                setIsShowModal(true);
              }
            : () => navigate(`/${trainingId}/switch2/countdown1`)
        }
        onBack={() => navigate(`/${trainingId}/stages`)}
        color={InstructionColor}
        logo={InstructionLogo}
      />
      <Dialog
        message={
          '前回の進捗があります。続きから始めますか？\n新規に始める場合は「新規に始める」を選択してください。'
        }
        isShow={isShowModal}
        cancelText={'新規に始める'}
        onCancel={() => {
          deleteData('switch2', trainingId);
          navigate(`/${trainingId}/switch2/countdown1`);
        }}
        onConfirm={() => {
          const currentPage =
            localStorage.getItem('currentpage-switch2') ?? 'countdown1';
          navigate(`/${trainingId}/switch2/${currentPage}`);
        }}
      />
    </CommonBG>
  ) : (
    <></>
  );
};

export default Switch2Rule;
