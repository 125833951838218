import { FinishButton } from '@riddler-co-jp/specc-ui-components';
import { ScoreText } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import TimeGauge from '../../../uiElements/timeGauge/TimeGauge';
import { CSVBingo, CSVReach, Item, Switch3State } from './Common';
import Theme from './Theme';
import { themes } from './WordList.json';

function getRandInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

function getRandVec(len: number, max: number) {
  let ret: number[] = [];
  if (len > max) return ret;
  const intSet = new Set();
  for (let i = 0; i < len; i++) {
    let tmp = getRandInt(0, max);
    while (intSet.has(tmp) === true) {
      tmp = getRandInt(0, max);
    }
    intSet.add(tmp);
    ret = [...ret, tmp];
  }
  return ret;
}

const Panel = (props: {
  id: number;
  pushCount: number;
  setPushCount: React.Dispatch<React.SetStateAction<number>>;
  words: string[];
  colors: number[];
  bingoCount: number;
  setBingoCount: React.Dispatch<React.SetStateAction<number>>;
  reachCount: number;
  setReachCount: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { trainingId } = useParams<'trainingId'>();
  const { width, height } = useWindowSize();
  const panelRef = useRef<HTMLDivElement | null>(null);
  const [panelWidth, setPanelWidth] = useState(
    `switch3-${trainingId}-panelWidth`,
    0
  );

  const push = () => {
    let bingoCounter = 0;
    let reachCounter = 0;

    /* 盤面の初期化 */
    if (props.colors[props.id] >= 1) props.colors[props.id] = 0;
    else props.colors[props.id] = 1;
    for (let i = 0; i < 16; i++) {
      if (props.colors[i] >= 1) props.colors[i] = 1;
    }

    /* 斜めラインの処理 */
    let tmp;
    tmp =
      props.colors[0] + props.colors[5] + props.colors[10] + props.colors[15];
    if (tmp === 4) {
      bingoCounter++;
      props.colors[0] = 2;
      props.colors[5] = 2;
      props.colors[10] = 2;
      props.colors[15] = 2;
    }
    if (tmp === 3) reachCounter++;
    tmp =
      props.colors[3] + props.colors[6] + props.colors[9] + props.colors[12];
    if (tmp === 4) {
      bingoCounter++;
      props.colors[3] = 2;
      props.colors[6] = 2;
      props.colors[9] = 2;
      props.colors[12] = 2;
    }
    if (tmp === 3) reachCounter++;

    /* 縦ラインの処理 */
    for (let i = 0; i < 4; i++) {
      let linePushCount = 0;
      for (let j = 0; j < 4; j++) {
        if (props.colors[j + i * 4] >= 1) linePushCount++;
      }
      if (linePushCount === 4) {
        bingoCounter++;
        for (let j = 0; j < 4; j++) {
          props.colors[j + i * 4] = 2;
        }
      } else if (linePushCount === 3) reachCounter++;
    }

    /* 横ラインの処理 */
    for (let i = 0; i < 4; i++) {
      let linePushCount = 0;
      for (let j = 0; j < 4; j++) {
        if (props.colors[i + j * 4] >= 1) linePushCount++;
      }
      if (linePushCount === 4) {
        bingoCounter++;
        for (let j = 0; j < 4; j++) {
          props.colors[i + j * 4] = 2;
        }
      } else if (linePushCount === 3) reachCounter++;
    }

    props.setPushCount(props.pushCount);
    props.setBingoCount(bingoCounter);
    props.setReachCount(reachCounter);
  };

  const states = Object.values(PanelState);

  useEffect(() => {
    if (panelRef.current?.clientWidth) {
      setPanelWidth(panelRef.current.clientWidth);
    }
  }, [panelRef, panelWidth, width, height]);

  return (
    <SinglePanel
      ref={panelRef}
      onClick={() => push()}
      state={states[props.colors[props.id]]}
    >
      <p
        style={
          panelWidth > 120
            ? { fontSize: '3.2rem', lineHeight: '3.9rem' }
            : { fontSize: '2.4rem', lineHeight: '2.8rem' }
        }
      >
        {props.words[props.id]}
      </p>
      <span>
        <CSVCheck />
      </span>
    </SinglePanel>
  );
};

const Board = (props: {
  words: string[];
  colors: number[];
  bingoCount: number;
  setBingoCount: React.Dispatch<React.SetStateAction<number>>;
  reachCount: number;
  setReachCount: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { trainingId } = useParams<'trainingId'>();
  const [pushCount, setPushCount] = useState<number>(
    `switch3-${trainingId}-pushCount`,
    0
  );

  const renderJpgpanel = (i: number) => {
    return (
      <Panel
        id={i}
        pushCount={pushCount + 1}
        setPushCount={setPushCount}
        words={props.words}
        colors={props.colors}
        bingoCount={props.bingoCount}
        setBingoCount={props.setBingoCount}
        reachCount={props.reachCount}
        setReachCount={props.setReachCount}
      />
    );
  };

  return (
    <BoardStyle>
      <li>
        {renderJpgpanel(0)}
        {renderJpgpanel(1)}
        {renderJpgpanel(2)}
        {renderJpgpanel(3)}
      </li>
      <li>
        {renderJpgpanel(4)}
        {renderJpgpanel(5)}
        {renderJpgpanel(6)}
        {renderJpgpanel(7)}
      </li>
      <li>
        {renderJpgpanel(8)}
        {renderJpgpanel(9)}
        {renderJpgpanel(10)}
        {renderJpgpanel(11)}
      </li>
      <li>
        {renderJpgpanel(12)}
        {renderJpgpanel(13)}
        {renderJpgpanel(14)}
        {renderJpgpanel(15)}
      </li>
    </BoardStyle>
  );
};

interface Stage3AnswerProps {
  theme: number;
}

const Stage3Answer: React.FC<Stage3AnswerProps> = props => {
  const { trainingId } = useParams<'trainingId'>();
  const [finished, setFinished] = useState<boolean>(
    `switch3-${trainingId}-finished`,
    false
  );
  const { width } = useWindowSize();
  const currentTheme = themes[props.theme - 1];
  const [colors, setColors] = useState<number[]>(
    `switch3-${trainingId}-colors`,
    [0]
  );
  const [words, setWords] = useState(`switch3-${trainingId}-words`, ['']);
  const [bingoCount, setBingoCount] = useState<number>(
    `switch3-${trainingId}-bingoCount`,
    0
  );
  const [reachCount, setReachCount] = useState<number>(
    `switch3-${trainingId}-reachCount`,
    0
  );
  const [resultSet, setResultSet] = useState<Switch3State>(
    `switch3-${trainingId}-resultSet`
  );
  const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    setFinished(false);
  }, [pathname]);

  useEffect(() => {
    const themeId = window.localStorage.getItem(
      trainingId + '-switch3-currentTheme'
    );
    if (themeId !== '' + props.theme) {
      let initColors: number[] = [];
      const vec2 = getRandVec(16, currentTheme.words.length);
      let newWords: string[] = [];
      for (let i = 0; i < 16; i++) {
        initColors = [...initColors, 0];
        newWords = [...newWords, currentTheme.words[vec2[i]]];
      }
      setWords(newWords);
      setColors(initColors);
      window.localStorage.setItem(
        trainingId + '-switch3-currentTheme',
        '' + props.theme
      );
    }
  }, [currentTheme.words]);

  const goTo = (url: string) => {
    navigate(url);
  };

  const onFinish = () => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const results: Item[] = resultSet?.results || [];
    results.push(data);
    setResultSet({ results });

    goTo(
      props.theme >= themes.length
        ? `/${trainingId}/switch3/result`
        : `/${trainingId}/switch3/${props.theme + 1}/introduction`
    );
  };

  const data: Item = {
    stage: props.theme,
    bingoCount,
    reachCount,
    words,
    colors,
  };

  return (
    <>
      <TimeGauge
        timerKey={trainingId + `-switch3-` + props.theme}
        duration={900}
        stageName={InstructionText[3].title}
        logo={InstructionLogo}
        color={InstructionColor}
        onSkip={onFinish}
        onFinished={() => {
          setFinished(true);
        }}
        onBack={() => {
          navigate(`/${trainingId}/stages`);
        }}
      />
      <Stage3AnswerWrapper>
        <div>
          {width >= 1200 && (
            <Theme current={props.theme - 1} themes={themes}>
              {currentTheme.title}
            </Theme>
          )}
          <Board
            words={words}
            colors={colors}
            bingoCount={bingoCount}
            setBingoCount={setBingoCount}
            reachCount={reachCount}
            setReachCount={setReachCount}
          />
          <Score>
            {width < 1200 && (
              <Theme current={props.theme - 1} themes={themes}>
                {currentTheme.title}
              </Theme>
            )}
            <ul>
              <li>
                <CSVBingo />
                <span>
                  <ScoreText align='right' color='#FF5414' value={bingoCount} />
                </span>
              </li>
              <li>
                <CSVReach />
                <span>
                  <ScoreText align='right' color='#4F78FF' value={reachCount} />
                </span>
              </li>
            </ul>
          </Score>
        </div>
        <FinishButton
          color={InstructionColor}
          finished={finished}
          click={onFinish}
        >
          {props.theme >= themes.length
            ? '以上で実習は終了です。お疲れ様でした！'
            : '次のお題に挑戦しましょう！'}
        </FinishButton>
      </Stage3AnswerWrapper>
    </>
  );
};

const Stage3AnswerWrapper = styled.div`
  width: 100%;
  padding: 2rem 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  > div {
    position: absolute;
    display: flex;
    justify-content: center;
  }
`;

const Score = styled.div`
  width: 250px;

  li {
    list-style: none;
  }

  > ul {
    span {
      margin: 10px 0;
      display: block;
    }
  }
`;

const PanelState = {
  DEFAULT: 'default',
  PUSH: 'push',
  BINGO: 'bingo',
};

type PanelState = typeof PanelState[keyof typeof PanelState];

interface PanelProps {
  state: PanelState;
}

const SinglePanel = styled.div`
  /*width: 130px;
  height: 130px;
*/
  width: 33.33%;
  hegiht: 100%;

  background: white;
  border-radius: 10px;
  margin: 4px 5px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  position: relative;
  transition: 0.2s;

  p {
    margin: 6px;
    user-select: none;
    opacity: 0.32;
    /*
    font-size: 3.2rem;
    line-height: 3.9rem;*/
    }
  }

  @keyframes showCheck {
    0% {
      transform: scale(1.2);
      opacity: 0;
    }
    60% {
      transform: scale(0.85);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  span {
    animation: 0.24s ease-out showCheck;
    svg {
      padding: 10px;
    }
  }

  ${(p: PanelProps) => {
    return (
      p.state == 'default' &&
      `transform: translateY(-3px);
      box-shadow: 0 3px 0 #aaaaaa;
      color: #343A40;
      p {
        opacity: 1;
      }
      span {
        display: none !important;
        animation: none;
      }
      `
    );
  }}

  ${(p: PanelProps) => {
    return p.state == 'push' && `background: #4F78FF;`;
  }}
  
  ${(p: PanelProps) => {
    return p.state == 'bingo' && `background: #FF5414;`;
  }}

  span {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const BoardStyle = styled.ul`
  background: #e9ecef;
  border-radius: 10px;
  padding: 6px;
  margin: 0 10px;

  width: calc(100vw - 500px);
  height: calc(100vw - 500px);

  width: calc(100vh - 10rem);
  height: calc(100vh - 10rem);

  max-width: 720px;
  max-height: 720px;
  min-width: 420px;
  min-height: 420px;

  li {
    display: flex;
    height: calc(100% / 4);
  }
`;

const CSVCheck = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='118'
      height='118'
      viewBox='0 0 118 118'
    >
      <path
        d='M59,7a52,52,0,1,0,52,52A52.059,52.059,0,0,0,59,7m0-7A59,59,0,1,1,0,59,59,59,0,0,1,59,0Z'
        fill='#fff'
      />
      <path
        d='M29.007,62.21l-.015.015L0,33.234l6.356-6.356L29,49.52,72.861,5.657l6.364,6.364-50.2,50.2Z'
        transform='translate(19.994 26.12)'
        fill='#fff'
      />
    </svg>
  );
};

export default Stage3Answer;
