import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import AuthErrorPage from './components/eventProvider/common/AuthErrorPage';
import RedirectPage from './components/eventProvider/common/RedirectPage';
import Store from './redux/store';
import { TrainingRouter } from './TrainingRouter';

export const App = () => {
  return <Router />;
};

export default App;

const Router = () => {
  return (
    <Provider store={Store}>
      <BrowserRouter>
        <Routes>
          <Route path='/auth-error' element={<AuthErrorPage />} />
          <Route path='/:trainingId' element={<TrainingRouter />} />
          <Route path='/:trainingId/*' element={<TrainingRouter />} />
          <Route path='/' element={<RedirectPage />} />
          <Route path='/*' element={<Navigate to='/' />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};
