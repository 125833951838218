import { CommonBG, Rule } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  SpeccText,
} from '../../../lib/instructionSettings';
import { parentURI } from '../../..';

interface RuleInstrctionProps {
  //stageId: number;
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
  onExit: (url: string) => void;
}

const FinishPage: React.FC<RuleInstrctionProps> = ({ onExit }) => {
  const { trainingId } = useParams<'trainingId'>();
  const stageSelectURI = `${parentURI}/trainings/${trainingId}/stages`;
  const navigate = useNavigate();
  const location = useLocation();
  const [resultSet] = useState<any>(
    `switch2-${trainingId}-resultSet`,
    location.state
  );

  const goTo = (url: string, params: any) => {
    window.setTimeout(() => {
      navigate(url, {
        state: params,
      });
    }, 0);
  };

  return (
    <CommonBG>
      <Rule
        mission={SpeccText.mission}
        description={SpeccText.description_finish}
        video={SpeccText.video}
        onBackText='戻る'
        onBack={() => {
          goTo(`/${trainingId}/stages`, resultSet);
        }}
        onStartText='終了する'
        onStart={() => onExit(stageSelectURI)}
        color={InstructionColor}
        logo={InstructionLogo}
      />
    </CommonBG>
  );
};

export default FinishPage;
