import {
  CommonBG,
  Result,
  ScoreText,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';
import { CSVBingo, CSVReach, Item, Switch3State } from './Common';
import { themes } from './WordList.json';

interface RuleInstrctionProps {
  //stageId: number;
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
  infomations?: string[];
  conditions?: string[];
  consider_condition?: string;
  conclusions?: string[];
}

interface BoardProps {
  words: string[];
  colors: number[];
}

const SingleStage: React.FC<BoardProps> = props => {
  return (
    <BoardStyle>
      {props.words.map((single, i) => {
        return (
          <Panel key={single} state={props.colors[i]}>
            <p>{single}</p>
          </Panel>
        );
      })}
    </BoardStyle>
  );
};

const Program3Rule: React.FC<RuleInstrctionProps> = () => {
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();
  const location = useLocation();
  const [resultSet] = useState<Switch3State>(
    `switch3-${trainingId}-resultSet`,
    location.state as Switch3State
  );

  const text = InstructionText[3];

  return (
    <CommonBG>
      <Result
        title={text.title}
        mission={text.mission}
        video={text.video_result}
        onBack={() => navigate(`/${trainingId}/stages`)}
        color={InstructionColor}
        logo={InstructionLogo}
      >
        {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          resultSet && (
            <>
              {resultSet.results.map((single: Item, i: number) => {
                return (
                  <Stage key={i}>
                    <SingleStage words={single.words} colors={single.colors} />
                    <Score>
                      <ThemeStyle>
                        <span>{i + 1}つ目のお題</span>
                        <div>{themes[single.stage - 1].title}</div>
                      </ThemeStyle>
                      <ul>
                        <li>
                          <CSVBingo />
                          <span>
                            <ScoreText
                              align='right'
                              color='#FF5414'
                              value={single.bingoCount}
                            />
                          </span>
                        </li>
                        <li>
                          <CSVReach />
                          <span>
                            <ScoreText
                              align='right'
                              color='#4F78FF'
                              value={single.reachCount}
                            />
                          </span>
                        </li>
                      </ul>
                    </Score>
                  </Stage>
                );
              })}
            </>
          )
        }
      </Result>
    </CommonBG>
  );
};

const Stage = styled.div`
  display: flex;
`;

const BoardStyle = styled.ul`
  flex: 1;
  background: #e9ecef;
  border-radius: 10px;
  padding: 6px;
  margin-right: 20px;
  margin-bottom: 20px;

  display: flex;
  flex-wrap: wrap;

  li {
    width: calc(100% / 4 - 8px);

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 4px;

    p {
      margin: 10px;
      font-size: 2.4rem;
      font-weight: bold;
      text-align: center;
    }
  }
`;

const Score = styled.div`
  width: 200px;
  overflow: hidden;

  li {
    list-style: none;

    span {
      margin: 10px 0;
      display: block;
    }

    > svg {
      width: 100%;
      height: auto;
    }
  }
`;

const ThemeStyle = styled.div`
  margin-bottom: 20px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  align-self: flex-start;

  > span {
    display: block;
    text-align: center;
    background: #4f78ff;
    color: white;
    font-weight: bold;
    font-size: 2rem;
    padding: 4px;
  }

  > div {
    text-align: center;
    background: white;
    font-weight: bold;
    font-size: 2rem;
    border: 1px solid #ced4da;
    white-space: pre-wrap;
    border-top: none;
    padding: 6px 10px;
    padding-bottom: 10px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      padding: 14px 0;
    }
  }
`;

interface PanelProps {
  state: number;
}

const Panel = styled.li`
  height: 130px;

  ${(p: PanelProps) => {
    return p.state == 0
      ? `
      background: white;
      color: #343A40;
    `
      : p.state == 1
      ? `
      background: #4F78FF;
      color: white;  
    `
      : `
      background: #FF5414;
      color: white;
    `;
  }}
`;

export default Program3Rule;
