type Stage = 'switch2' | 'switch3';

const switch2KeyList = (trainingId: string) => [
  `@useStatePerist:switch2-${trainingId}-resultSet`,
  `@useStatePerist:switch2-${trainingId}-finished`,
  `@useStatePerist:switch2-${trainingId}-activeStep3`,
  `@useStatePerist:switch2-${trainingId}-tempFeatures`,
  `@useStatePerist:switch2-${trainingId}-tempFeaturesRev`,
  `@useStatePerist:switch2-${trainingId}-selectedFeatures`,
  `@useStatePerist:switch2-${trainingId}-tempIdeas`,
  `@useStatePerist:switch2-${trainingId}-input`,
  `@useStatePerist:switch2-${trainingId}-lastCount`,
  'timer:' + trainingId + `-switch2-0`,
  'timer:' + trainingId + `-switch2-1`,
];

const switch3KeyList = (trainingId: string) => [
  `@useStatePerist:switch3-${trainingId}-panelWidth`,
  `@useStatePerist:switch3-${trainingId}-pushCount`,
  `@useStatePerist:switch3-${trainingId}-finished`,
  `@useStatePerist:switch3-${trainingId}-colors`,
  `@useStatePerist:switch3-${trainingId}-words`,
  `@useStatePerist:switch3-${trainingId}-bingoCount`,
  `@useStatePerist:switch3-${trainingId}-reachCount`,
  `@useStatePerist:switch3-${trainingId}-resultSet`,
  'timer:' + trainingId + `-switch3-0`,
  'timer:' + trainingId + `-switch3-1`,
  trainingId + '-switch3-currentTheme',
];

export const hasData = (stage: Stage, trainingId: string) => {
  const list =
    stage === 'switch2'
      ? switch2KeyList(trainingId)
      : switch3KeyList(trainingId);
  return list.some(key => localStorage.getItem(key));
};
export const deleteData = (stage: Stage, trainingId: string) => {
  const list =
    stage === 'switch2'
      ? switch2KeyList(trainingId)
      : switch3KeyList(trainingId);
  list.forEach(key => localStorage.removeItem(key));
};
