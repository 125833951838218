import { CommonBG, Rule } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  InstructionColor,
  InstructionLogo,
  InstructionText,
} from '../../../../lib/instructionSettings';

interface RuleInstrctionProps {
  //stageId: number;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
}

const Switch1Rule: React.FC<RuleInstrctionProps> = () => {
  const navigate = useNavigate();
  const { trainingId } = useParams<'trainingId'>();

  const text = InstructionText[1];

  return (
    <CommonBG>
      <Rule
        title={text.title}
        mission={text.mission}
        description={text.description}
        video={text.video}
        onStart={() => navigate(`/${trainingId}/switch1/page2`)}
        onBack={() => navigate(`/${trainingId}/stages`)}
        color={InstructionColor}
        logo={InstructionLogo}
      />
    </CommonBG>
  );
};

export default Switch1Rule;
