export interface WordList {
  themes: SingleTheme;
}

export interface SingleTheme {
  title: string;
  words: string[];
}

export interface Switch3State {
  results: Item[];
}

export interface Item {
  stage: number;
  bingoCount: number;
  reachCount: number;
  words: string[];
  colors: number[];
}

export const CSVBingo: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='250'
      height='30'
      viewBox='0 0 250 30'
    >
      <g transform='translate(-935 -90)'>
        <g transform='translate(964.605 89.938)'>
          <g transform='translate(0 0)'>
            <g transform='translate(50.395 0.063)'>
              <rect
                width='203'
                height='30'
                transform='translate(-80)'
                fill='#ff5414'
              />
              <rect
                width='20'
                height='30'
                transform='translate(127)'
                fill='#ff5414'
              />
              <rect
                width='20'
                height='30'
                transform='translate(151)'
                fill='#ff5414'
              />
            </g>
          </g>
        </g>
        <path
          d='M-10.62-10.9v-2.24h-3.86v-.78h3.86V-16.2h-4.86v1.66h-7.16v-1.62h-5.62V-6.08c0,4.76,2.24,7.32,8.64,7.32h8.42v-5.6h-6.9c-3.92,0-4.54-.48-4.54-2.26V-8.9h10.86v-2Zm9.82.92v-5.58H-9.08v5.58Zm9.96.6V-15.8c-4.6.54-5.76,2.38-6.72,7.16C1.92-6,.92-4.44-4.32-4.44H-9.14V1.2h5.52c7.24,0,11-2.8,11.9-9.42A1.183,1.183,0,0,1,9.16-9.38Zm20.24-1.7v-2.26H25.66v-.76H29.4v-2.28H24.52v.72a20.773,20.773,0,0,0-3-.18H11.36V-10H21.52c.88,0,1.02.22,1.02.96v4.46H11.36V1.24h17.1V-9.04c0-.74-.02-1.42-.08-2.04Z'
          transform='translate(1101 112)'
          fill='#fff'
        />
      </g>
    </svg>
  );
};

export const CSVReach: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='250'
      height='30'
      viewBox='0 0 250 30'
    >
      <g transform='translate(-935 -215)'>
        <g transform='translate(964.605 214.938)'>
          <g transform='translate(0 0)'>
            <g transform='translate(50.395 0.063)'>
              <rect
                width='203'
                height='30'
                transform='translate(-80)'
                fill='#4f78ff'
              />
              <rect
                width='20'
                height='30'
                transform='translate(127)'
                fill='#4f78ff'
              />
              <rect
                width='20'
                height='30'
                transform='translate(151)'
                fill='#4f78ff'
              />
            </g>
          </g>
        </g>
        <path
          d='M-11.46-9.02v-7.12h-5.9v7.02c0,3.4-1.16,4.54-4.1,4.54h-4.96V1.24h5.14C-15.02,1.24-11.46-.9-11.46-9.02Zm-11.26,3.1V-16.14H-28.6V-5.92ZM8.56-4.72v-5.96H-8.58v5.96Zm20.52-.56V-9.8H27.1v-3.08a5.665,5.665,0,0,0,.92-3.4H21.36c0,.64-.4.96-1.54.96H11.38v4.72H20.1c.3,0,.62-.02.92-.02v.82H10.88v4.52h9.86c-.5.68-1.74,1.16-4.12,1.16H11.38V1.3h5.18c6.12,0,9.82-2.48,10.44-6.58Z'
          transform='translate(1102 237)'
          fill='#fff'
        />
      </g>
    </svg>
  );
};
