import { CommonBG } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-use';
import styled from 'styled-components';

import Switch3Answer from './Switch3Answer';
import { themes } from './WordList.json';

interface Stage3Props {}

const Stage3: React.FC<Stage3Props> = () => {
  const params = useParams<'stage'>();
  if (Number.isNaN(parseInt(params.stage ?? 'undefined'))) {
    return null;
  }

  const stage_id = parseInt(params.stage ?? 'undefined');
  if (stage_id > themes.length) {
    return null;
  }

  return (
    <CommonBG>
      <MainWrapper>
        <Switch3Answer theme={stage_id} />
      </MainWrapper>
    </CommonBG>
  );
};

const MainWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 3rem);
  margin: 3rem auto 0;
  display: flex;
  overflow: hidden;
`;

export default Stage3;
