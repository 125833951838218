import { CommonBG, CountDown } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useStatePersist as useState } from 'use-state-persist';

import { Switch3State } from './Common';
import { themes } from './WordList.json';

interface CountDownProps {}
const Switch1CountDown: React.FC<CountDownProps> = () => {
  const { trainingId, stage } = useParams<'trainingId' | 'stage'>();

  const location = useLocation();
  const [resultSet] = useState<Switch3State>(
    `switch3-${trainingId}-resultSet`,
    location.state as Switch3State
  );

  if (Number.isNaN(parseInt(stage ?? 'undefined'))) {
    return null;
  }

  const stage_id = parseInt(stage ?? 'undefined');
  if (stage_id - 1 > themes.length) {
    return null;
  }
  return (
    <CommonBG>
      <CountDown
        redirect={`/${trainingId}/switch3/${stage_id}/game`}
        state={resultSet}
      />
    </CommonBG>
  );
};

export default Switch1CountDown;
